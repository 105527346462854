<template>
<section class=" mb-5">
    <div class="container mb-5">
        <div class="row mb-4">
            <div class="col-12 col-md-6 col-lg-6 mb-4">
                <div class="card border-0 rounded-lg">
                    <div class="card-header bg-transparent"><h3 class="card-title"><svg role="img" class="icon mb-1 fill-primary mr-1"><use xlink:href="#calendar-add"></use></svg> Maak een afspraak</h3></div>
                    <div class="card-body">
                        <p class="">Maak via de knop hieronder een afspraak met een zorgverlener. Afspraak maken voor een kind/iemand anders <a href="afspraak-maken/kind-1.html">klik hier</a></p>
                        <p>
                          <router-link class="btn btn-lg btn-primary"  to="/afspraken/create">Maak een afspraak</router-link> 
                        </p>
                        
                    </div>
                 
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6 mb-3">
                <div class="card border-0 rounded-lg">
                    <div class="card-header bg-transparent">
                      <h3 class="card-title"><svg role="img" class="icon mb-1 fill-primary mr-1"><use xlink:href="#check"></use></svg> Klachtenchecker</h3></div>
                    <div class="card-body">
                        <p class="">Je hebt een klacht. Maar moet ik ermee naar de huisarts? Doe de klachtenchecker en we helpen je met de vervolgstappen.</p>
                <p><a href="step-1.html" class="btn btn-light mb-2">Naar de Klachtenchecker<svg role="img" class="icon icon-arrow-circle-back icon-primary ml-2">
                    <use xlink:href="#arrow-forward"></use>
                </svg></a></p>
                    </div>
                </div>
            </div>
        </div>
      


       

      <h3 class="sectionheader">Gemaakte afspraken:</h3>
      <p></p>
  
      <div class="list-group list-group-flush rounded p-2 bg-white shadow">
        <div class="list-group-item p-2">
          <div class="d-flex flex-wrap align-items-center">
            
              <div class="calendar calendar-primary text-white">
                  <strong class="day">31</strong>
                  <small class="month">aug. '20</small>
              </div>
              <div class="flex-grow-1 p-2 d-flex">
                  <div class="flex-grow-1">
                      <div class="">
                          <strong>Naam arts</strong>
                      </div>
                      <div>
                          <svg role="img" class="icon icon-video fill-primary"><use xlink:href="#video"></use></svg> <span>video afspraak 19:45 - 20:00</span>
                      </div>
                  </div>
                  <div>
                  </div>
              </div>
              <div class="d-block d-sm-none"><button class="btn btn-link btn-small"><svg role="img" class="icon fill-primary"><use xlink:href="#arrow-forward"></use></svg></button></div>
              <div class="d-none d-sm-block">
                <div class="btn-group">
                    <button type="button" class="btn btn-light">Bekijken</button>
                    <button type="button" class="btn btn-light dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <svg role="img" class="icon fill-secondary"><use xlink:href="#more"></use></svg>
                    </button>
                    <div class="dropdown-menu">
                        
                        <a class="dropdown-item" href="#"><svg role="img" class="icon icon-sm fill-primary"><use xlink:href="#calendar-edit"></use></svg> Aanpassen</a>
                        <a class="dropdown-item" href="#"><svg role="img" class="icon icon-sm fill-danger"><use xlink:href="#calendar-del"></use></svg> Annuleren</a>
                    </div>
                  </div>
                </div>
          </div>
      </div>
      <!-- start of list item -->
      <div class="list-group-item p-2">
        <div class="d-flex flex-wrap align-items-center">
            <div class="calendar calendar-primary text-white">
                <strong class="day">31</strong>
                <small class="month">aug. '20</small>
            </div>
            <div class="flex-grow-1 p-2 d-flex">
                <div class="flex-grow-1">
                    <div class="">
                        <strong>Naam arts</strong>
                    </div>
                    <div>
                        <svg role="img" class="icon icon-video fill-primary"><use xlink:href="#video"></use></svg> <span>video afspraak 19:45 - 20:00</span>
                    </div>
                </div>
                <div>
                </div>
            </div>
            <div class="d-block d-sm-none"><button class="btn btn-link btn-small"><svg role="img" class="icon fill-primary"><use xlink:href="#arrow-forward"></use></svg></button></div>
            <div class="d-none d-sm-block">
                <div class="btn-group">
                    <button type="button" class="btn btn-light">Bekijken</button>
                    <button type="button" class="btn btn-light dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <svg role="img" class="icon fill-secondary"><use xlink:href="#more"></use></svg>
                    </button>
                    <div class="dropdown-menu">
                        
                        <a class="dropdown-item" href="#"><svg role="img" class="icon icon-sm fill-primary"><use xlink:href="#calendar-edit"></use></svg> Aanpassen</a>
                        <a class="dropdown-item" href="#"><svg role="img" class="icon icon-sm fill-danger"><use xlink:href="#calendar-del"></use></svg> Annuleren</a>
                    </div>
                  </div>
              </div>
        </div>
    </div>
    <!-- end of list item -->
  
    <!-- start of list item -->
    <div class="list-group-item p-2 history">
      <div class="d-flex flex-wrap align-items-center">
          <div class="calendar calendar-gray-200 text-white">
              <strong class="day">31</strong>
              <small class="month">aug. '20</small>
          </div>
          <div class="flex-grow-1 p-2 d-flex">
              <div class="flex-grow-1">
                  <div class="">
                      <strong>Naam arts</strong>
                  </div>
                  <div>
                      <svg role="img" class="icon icon-video fill-primary"><use xlink:href="#video"></use></svg> <span>video afspraak 19:45 - 20:00</span>
                  </div>
              </div>
              <div>
              </div>
          </div>
          <div class="d-block d-sm-none"><button class="btn btn-link btn-small"><svg role="img" class="icon fill-primary"><use xlink:href="#arrow-forward"></use></svg></button></div>
          <div class="d-none d-sm-block">
              <div class="btn-group"></div>
              <button type="button" class="btn btn-light dropdown-toggle dropdown-toggle-split" data-toggle="dropdown">
                  <svg role="img" class="icon icon-video fill-secondary"><use xlink:href="#more"></use></svg> 
              </button>
              <div class="dropdown-menu">
                <a class="dropdown-item" href="#"><svg role="img" class="icon icon-sm fill-primary"><use xlink:href="#calendar-edit"></use></svg> Aanpassen</a>
                <a class="dropdown-item" href="#"><svg role="img" class="icon icon-sm fill-danger"><use xlink:href="#calendar-del"></use></svg> Annuleren</a>
                
              </div>
            </div>
      </div>
  </div>
  <!-- end of list item -->
      </div>    
    </div>
  </section>
  
</template>