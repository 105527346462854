import { createRouter, createWebHistory } from 'vue-router'
import AppointmentsOverview from '../views/AppointmentsOverview.vue'

const routes = [
  {
    path: '/',
    name: 'afspraken',
    component: AppointmentsOverview,
    meta: {
      title: 'Afspraken uit Meta'
    }
  },
  {
    path: '/afspraken/create',
    name: 'create',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "create" */ '../views/createAppointment/TypeSelect.vue')
  },
  {
    path: '/afspraken/reason',
    name: 'reason',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "reason" */ '../views/createAppointment/ReasonInput.vue'),
    // beforeEnter: (to, from, next) => {
    //     if(from.name !== 'create') {
    //       next({ name: 'create' })
    //     } else {
    //       next()
    //     }
    //   }      
    },
    {
      path: '/afspraken/timeselect',
      name: 'timeselect',
      component: () => import(/* webpackChunkName: "timeselect" */ '../views/createAppointment/TimeSelect.vue')
    },    
    {
      path: '/afspraken/confirm',
      name: 'confirm',
      component: () => import(/* webpackChunkName: "confirm" */ '../views/createAppointment/CheckInput.vue')
    },
    {
      path: '/afspraken/completed',
      name: 'completed',
      component: () => import(/* webpackChunkName: "completed" */ '../views/createAppointment/AppointmentCompleted.vue')
    }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: () => {
    return { x: 0, y: 0 }
  }
})





export default router