import { createStore } from 'vuex'


export default createStore({
  state: {
    progressSteps: [
      "Waarvoor",
      "Reden",
      "Wanneer",
      "Controle"
    ],
    triageItems: {
    },
    agendaList: [
      {
        id: 1,
        name: "Marit",
        image: "marit.jpg"
      },
      {
        id: 2,
        name: "Marcel",
        image: "marcel.jpg"
      },
      {
        id: 3,
        name: "Anne-Roos",
        image: ""
      },
      {
        id: 4,
        name: "Amon"
      },
      {
        id: 5,
        name: "Stefanie"
      },
      {
        id: 6,
        name: "Valentina"
      },
      {
        id: 7,
        name: "Wout",
        image: "wout.jpg"
      }
    ],
    selectedTriageItem: {
    },
    Timeslots: {
    },
    selectedTimeslot: null,
    selectedDayPart: 0,
    selectedProfAndType: null,
    appointmentData: null,
    appointmentFlow: {
      title: "",
      currentStep: 0,
    }
  },
mutations: {
  selectedTriageItem (state, triageItem) {
    state.selectedTriageItem = triageItem
  },
  appointmentData (state, info) {
    state.appointmentData = info
  },
  triageData (state, info) {
    state.triageItems = info
  },
  appointmentFlow (state, data) {
    state.appointmentFlow = data
  },
  Timeslots (state, timeSlots) {
    state.Timeslots = timeSlots
  },
  selectedTimeslot (state, data) {
    state.selectedTimeslot = data
  },
  selectedDayPart (state, data) {
    state.selectedDayPart = data
  },
  selectedProfAndType (state, data) {
    state.selectedProfAndType = data
  },
  selectedProf (state, data) {
    state.selectedProfAndType.agenda = data
  },
  selectedType (state, data) {
    state.selectedProfAndType.appointmentType = data
  }

},
actions: {
  selectedTriageItem (context, data) {
    context.commit('selectedTriageItem', data)
  },
  appointmentData (context, data) {
  context.commit('appointmentData', data)
  }
},
getters: {
  getTriageItemByType: (state) => (type) => {
    return state.triageItems.find(triageItem => triageItem.type === type)
  }
}
});